import React from 'react'
import './style.css'

const Vision = () => {
  return (
    <>
<div className="container">
<div className='about-vision'>
      <h2 className='section-heading'>Our Vision</h2>
      <p className='sub-heading-section'>Building a Secure and Trusted Foundation for the Decentralized Future.</p>
      <div className='table'>
      <table>
        <tbody>
            <tr>
                <td>Securing DeFi:</td>
                <td>Empowering decentralized finance with robust security measures, ensuring safety for funds and protocols in a rapidly evolving ecosystem.</td>
            </tr>
            <tr>
                <td>Transparent Growth:</td>
                <td>Fostering trust and accountability by delivering clear, comprehensive, and reliable auditing services that prioritize stakeholder interests. </td>
            </tr>
            <tr>
                <td>Blockchain Adoption:</td>
                <td>Accelerating global blockchain adoption by mitigating risks and building secure foundations for innovative decentralized applications.</td>
            </tr>
            <tr>
                <td>Web3 Confidence:</td>
                <td>Strengthening confidence in Web3 by safeguarding its infrastructure, enabling users to explore and innovate without fear of vulnerabilities.</td>
            </tr>
        </tbody>
    </table>

      </div>
    </div>
</div>
      {/* Mobile version */}
    </>
  )
}

export default Vision



import React from 'react';
import './style.css';
import PrimaryBtn from '../Primarybutton';
import Formpopup from '../Formpopup';
import iconarrow from '../../assets/images/iconarrow.svg'; // Correctly import the icon

const LandingCta = ({ title }) => {
  return (
  <>
      <div className="cta-wrapper-2">
        <h2>{title}</h2>
      <a href='#top'> <button className='btn btn-primary landingcta-btn '>Request Audit <img src={iconarrow} alt="" srcset="" /></button></a>
      </div>
      </>
  );
};

export default LandingCta;

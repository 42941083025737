import React, { useState } from "react";
import Culture from "../../components/About-Culture";
import Main from "../../components/About-main";
import Vision from "../../components/About-vision";
import Map from "../../components/About-map";
import Success from "../../components/About-success";
import AuditProcessdummy from "../../components/Services/penetrationTesting/auditprocessdummy";
import icon1 from '../../assets/images/about-team/bilic.io.svg'
import icon2 from '../../assets/images/about-team/mexc-global.svg'
import icon3 from '../../assets/images/about-team/bitmart.com.svg'
import icon4 from '../../assets/images/about-team/bilic.io2.svg'
import icon5 from '../../assets/images/about-team/mexc-global2.svg'
import icon6 from '../../assets/images/about-team/bitmart2.svg'

import './style.css'
import PrimaryBtn from "../../components/Primarybutton";
import Cta from "../../components/Cta-components";
import VideoTestimonial from "../../components/Video-Testimonial";
import Formpopup from '../../components/Formpopup';
import iconarrow from '../../assets/images/iconarrow.svg'; // Correctly import the icon

const About = () => {
  const processSteps = [
    {
      icon:icon1,
      title: "Billic",
      subTitleProcess: "",
      description:
        "A cyber security startup with an extensive product suite to focus on digital asset forensics, fraud prevention techniques and secured payment services.",
    },
    {
      icon:icon2,
      title: "MEXC global",
      subTitleProcess: "",
      description:
        "One of the first movers and pioneers of DeFi and blockchain, MEXC Global aims to become the go-to platform for traders and investors with their products - Futures, Spot and Leveraged ETFs.",
    },
    {
      icon:icon3,
      title: "BitMart",
      subTitleProcess: "",
      description:
        "One of the most trusted cryptocurrency trading platforms with an added ease of use, 800+ trading pairs, hot and cold wallet systems and an advanced risk management system.",
    },
    {
      icon:icon4,
      title: "Pando Infinity",
      subTitleProcess: "",
      description:
        "A full-stack software development outsourcing company based in Hanoi, specializing in Blockchain Development, Web/Mobile App Development, and AI & Machine Learning solutions. They have partnered with over 65 clients and completed more than 120 successful projects.",
    },
    {
      icon:icon5,
      title: "Gamevolution",
      subTitleProcess: "",
      description:
        "A blockchain incubator for gaming companies, partnering with video game developers to integrate blockchain technology into their creations. They focus on creating new business opportunities by enhancing player experiences through innovative technology.",
    },
    {
      icon:icon6,
      title: "Kreatorverse",
      subTitleProcess:
        "",
      description:
        "A Web3 Venture Studio that leverages expertise in blockchain and AI to help businesses build, launch, and scale internet ventures. They offer services such as venture building, product design, digital infrastructure, and strategic consulting",
    },
    {
      icon:icon1,
      title: "Extra Process 1",
      subTitleProcess: "",
      description: "Description for extra process step 1.",
    },
    {
      icon:icon2,
      title: "Extra Process 2",
      subTitleProcess: "",
      description: "Description for extra process step 2.",
    },
    {
      icon:icon3,
      title: "Extra Process 3",
      subTitleProcess: "",
      description: "Description for extra process step 3.",
    },
    {
      icon:icon1,
      title: "Extra Process 4",
      subTitleProcess: "",
      description: "Description for extra process step 4.",
    },
    {
      icon:icon2,
      title: "Extra Process 5",
      subTitleProcess: "",
      description: "Description for extra process step 5.",
    },
    {
      icon:icon3,
      title: "Extra Process 6",
      subTitleProcess: "",
      description: "Description for extra process step 6.",
    },

  ];



  const [visibleSteps, setVisibleSteps] = useState(6); // Initially display six steps

  const handleViewMore = () => {
    // console.log("View More clicked");
    setVisibleSteps(processSteps.length); // Show all steps
  };

  const handleViewLess = () => {
    // console.log("View Less clicked");
    setVisibleSteps(6); // Show only the initial six steps
  };

// Video Testimonial 
const testimonialsData = [
  {
    name: 'Adam Boudjemaa',
    position: 'Lead Blockchain Developer',
    company: 'Polytrade Finance',
    videoLink: 'IhFuCwL-VJg', 
  },
  {
    name: 'Jérémie Lepetit',
    position: 'Co-founder & CEO',
    company: 'Metaweb',
    videoLink: 'JD_rpOmeaZk', // Replace with an actual YouTube video ID
  },
  {
    name: 'Dr. Gabriel Allred',
    position: 'Founder',
    company: 'Biketter Labs',
    videoLink: 'aoQHInAOQCU', // Replace with an actual YouTube video ID
  },
  {
    name: 'Ebrahiem Mohamed',
    position: 'Founder',
    company: 'Ethereum STK',
    videoLink: 'oxry0sps1zQ', // No video link provided
  },
];




  return (
    <div className="about">
      <Main />
<div className="container">
<Cta title="Leading the Wave of Web3 Security" />
</div>

      <Vision />


      <div className="about-process about-audit-process">
        <AuditProcessdummy
        title="Our Partners"
subheading="We are committed to raising the bar for Web3 safety through meticulous audits and innovative security solutions. By addressing vulnerabilities proactively, we empower blockchain projects to operate with confidence and resilience."
          processSteps={processSteps.slice(0, visibleSteps)} // Display limited steps
        />
      
        {visibleSteps < processSteps.length ? (
          <PrimaryBtn className='view-more-btn ' text="View More" onClick={handleViewMore} />
        ) : (
          <PrimaryBtn text="View Less" onClick={handleViewLess} />
        )}
         
      </div>
      {/* <Formpopup
                auditName="Request Audit"
                buttonClassName="btn btn-primary open-popup-btn "
                arrowicon={iconarrow} // Pass the imported icon
              /> */}
      <Success />
      <Map />
      <VideoTestimonial
      title="Video Testimonial"
      description="Hear directly from our clients as they share their experiences with ImmuneBytes. Discover how our Web3 security expertise has helped them achieve their goals with confidence and trust."
      testimonials={testimonialsData}
    />
      <Culture />
      <div className="secure-audit">
<h2>
Stay Ahead of the Security Curve.
</h2>
</div>
    </div>
  );
};

export default About;

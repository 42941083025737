import React,{useEffect} from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the carousel styles
import Formpopup from '../Formpopup';
import './AuditCarousel.css';
import { useState } from 'react';

const AuditCarousel = () => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.audit-carousel')) {
        setIsAutoPlay(true);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);
  const slides = [
    {
      id: 1,
      title: 'Smart Contract Audit',
      description:
        "An extensive evaluation of your smart contract code for security, verification of business specifications, and adherence to industry standards for code reusability.",
    },
    {
      id: 2,
      title: 'Blockchain Security Audit',
      description:
        "A thorough assessment of your blockchain’s security posture, encompassing architecture, network layer, transaction layer, ledger layer, and other frameworks.",
    },
    {
      id: 3,
      title: 'Penetration Testing',
      description:
        "Amid the rising incidence of traditional security breaches affecting Web3, ImmuneBytes provides penetration testing services explicitly tailored for Web3 applications.",
    },
  ];
  const [isAutoPlay, setIsAutoPlay] = useState(true);

  return (
    <div className="audit-carousel">
      <Carousel
        autoPlay
        interval={3000}
        infiniteLoop
        showArrows={false} // Remove left/right navigation icons
        showStatus={false} // Remove the status text
        showIndicators={false} // Remove the dots below the carousel
        stopOnHover={true}
        swipeable
        emulateTouch
        transitionTime={1000} // Smooth transition time (1 second)
        onMouseEnter={() => setIsAutoPlay(false)}
  onMouseLeave={() => setIsAutoPlay(true)}
  onClickItem={() => setIsAutoPlay(false)} // Pause when clicked
      >
        {slides.map((slide) => (
          <div key={slide.id} className="audit-slide">
            <h3>{slide.title}</h3>
            <p>{slide.description}</p>
          </div>
        ))}
      </Carousel>
      <Formpopup auditName="Contact Us" buttonClassName="register-btn" />
    </div>
  );
};

export default AuditCarousel;

import React from "react";
import "./style.css";
import PrimaryBtn from "../Primarybutton";
import FormpopupLandingPage from "../Form-Landing-Page/index.jsx";
import iconarrow from '../../assets/images/arrowicon.svg'



const HeroSectionLandingPage = ({ 
  title, 
  highlight, 
  description, 
  imageSrc, 
  altText 
}) => {
  return (
    <div className="container">
      <div className="hero-section-landing-page">
        <div className="hero-content-landing-page">
          <h1 className="homepage-heading">
            <span>{title}</span>
             <span className="highlight"> {highlight} </span>
          </h1>
          <p>{description}</p>
          <div className="btn-wrapper dekstop-hidden">
          {/* <Formpopup
          auditName="Book Consultation"
          buttonClassName="btn btn-primary"
          arrowicon={iconarrow} // Pass the imported icon
        />
         */}
          <PrimaryBtn text="Book Consultation" onClick={() => window.location.href = "https://calendly.com/aabhas-sood/15min?month=2025-02"} />
          </div>
        </div>
  
        <div className="btn-wrapper mobile-hidden">
        <PrimaryBtn text="Book Consultation" onClick={() => window.location.href = "https://calendly.com/aabhas-sood/15min?month=2025-02"} />
          </div>
          <div className="hero-video">
       <FormpopupLandingPage/>
        </div>
      </div>
    </div>
  );
};

export default HeroSectionLandingPage;

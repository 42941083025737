import './style.css';
import PrimaryBtn from '../Primarybutton';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";

function MyComponent() {
  const navigate = useNavigate()
  const handleBackToHome = () => {
    navigate("/");
  };


  return (
    <>
   <Helmet>
  <script
    dangerouslySetInnerHTML={{
      __html: `
        gtag('event', 'conversion', {
          'send_to': 'AW-16882285533/6J0RCJe30Z8aEN33jPI-',
          'value': 1.0,
          'currency': 'INR'
        });
      `,
    }}
  />
</Helmet>
<div className="thankyou-section">
<div className="container">
      <h1 className="heading-thank">
        Thank <span className="highlight-you">You!</span>
      </h1>
      {/* <img src="https://res.cloudinary.com/dd81sfnzh/raw/upload/v1739818106/cq5pqf7bqfa9eewfe2r2.png" alt="PDF First Page"/> */}

      <p className="description-thank">We are glad to receive your query! One of our team members will connect with you shortly and answer any questions you may have. Your security concerns are our highest priority now. Let’s get this journey started!</p>
<div className="thank-btn">
<PrimaryBtn text="Back To Home" onClick={handleBackToHome} />
</div>

<div className="secure-audit">
<h2>
Stay Ahead of the Security Curve.
</h2>
</div>

    </div>
</div>
</>
  );
}

export default MyComponent;

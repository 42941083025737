import React from 'react';
import './style.css'; // Your custom styles

// Import images for culture slider
import culture1 from '../../assets/images/about-culture/culture (2).jpg';
import culture2 from '../../assets/images/about-culture/culture (3).jpg';
import culture3 from '../../assets/images/about-culture/culture (4).jpg';
import culture4 from '../../assets/images/about-culture/culture (5).jpg';
import culture5 from '../../assets/images/about-culture/culture (6).jpg';
import culture6 from '../../assets/images/about-culture/culture (7).jpg';
import culture8 from '../../assets/images/about-culture/culture (8).jpg';
import culture9 from '../../assets/images/about-culture/culture (9).jpg';
import culture10 from '../../assets/images/about-culture/culture (10).jpg';
import culture11 from '../../assets/images/about-culture/culture (11).jpg';
import culture12 from '../../assets/images/about-culture/culture (12).jpg';
import culture13 from '../../assets/images/about-culture/culture (13).jpg';
import culture14 from '../../assets/images/about-culture/culture (14).jpg';
import culture15 from '../../assets/images/about-culture/culture (15).jpg';
import culture16 from '../../assets/images/about-culture/culture (16).jpg';
import culture17 from '../../assets/images/about-culture/culture (17).jpg';
import culture7 from '../../assets/images/about-culture/culture1.jpg';

const cultureImages1 = [culture1, culture2, culture3, culture4, culture5, culture6, culture7, culture8,culture1, culture2, culture3, culture4, culture5, culture6, culture7, culture8,culture1, culture2, culture3, culture4, culture5, culture6, culture7, culture8];
const cultureImages2 = [culture9, culture10, culture11, culture12, culture13, culture14, culture15, culture16, culture17, culture9, culture10, culture11, culture12, culture13, culture14, culture15, culture16, culture17,culture9, culture10, culture11, culture12, culture13, culture14, culture15, culture16, culture17,]; // Duplicate for continuous effect


const CultureMarquee = () => {
  return (
    <div className="culture-wrapper">
      <div className="container">
        <h1 className="text-center heading-h1 culture-heading">Cultural Insights</h1>
        <p className="sub-heading-section pb-4">
          At ImmuneBytes, our culture is built on innovation, integrity, and collaboration. We strive to create a 
          supportive environment that fosters creativity, values diverse perspectives, and empowers our team to push the boundaries of Web3 security.
        </p>
      </div>

      {/* First Marquee Section */}
      <div className="marquee">
        <div className="marquee-content" move="left">
          {cultureImages1.map((image, index) => (
            <img key={index} src={image} alt={`Culture ${index + 1}`} />
          ))}
          {/* Duplicate images to create smooth scrolling effect */}
          {cultureImages1.map((image, index) => (
            <img key={`dup-${index}`} src={image} alt={`Culture ${index + 1}`} />
          ))}
        </div>
      </div>

      {/* Second Marquee Section (Reverse Direction) */}
      <div className="marquee marquee-reverse">
        <div className="marquee-content" move="left">
          {cultureImages2.map((image, index) => (
            <img key={index} src={image} alt={`Culture ${index + 1}`} />
          ))}
          {cultureImages2.map((image, index) => (
            <img key={`dup-rev-${index}`} src={image} alt={`Culture ${index + 1}`} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CultureMarquee;


import React, { useState, useEffect } from 'react';
import DashboardHeader from '../Dashboard-Header';
import Sidebar from '../Dashboard-Sidebar';
import './style.css';
import AuditStats from '../Audit-Stats';
import AuditProgress from '../../components/Audit-Progress';
import PortfolioTable from '../../components/PortfolioTable';
import PlatformList from '../Platform-List';
import axios from 'axios';
import PlatformManagement from '../Add-Platform'

function DashboardMain() {
  const [platforms, setPlatforms] = useState([]);
  const [error, setError] = useState('');
  // const appuri = process.env.REACT_APP_URL|| "http://localhost:3000"
  const appuri =` ${process.env.REACT_APP_URL}/api/v1/users/getplatforms`|| "http://localhost:3000";


  useEffect(() => {
    const fetchPlatforms = async () => {
      try {
        const response = await axios.post(appuri, { withCredentials: true });
        if (response.data.statusCode === 200) {
          setPlatforms(response.data.data.platforms);
        } else {
          setError('Failed to fetch platforms');
        }
      } catch (err) {
        console.error(err);
        setError('Failed to fetch platforms');
      }
    };

    fetchPlatforms();
  }, []);

  return (
    <div className="main-container">
      <AuditStats />
      <div className="audit-edit-wrapper">
        <AuditProgress className="dasboard-progressbar additional-class" />
        {/* <PlatformManagement headname="Manage Platforms" platformsPerPage={3} /> */}
      </div>
      <div className="dashboard-table-container">
        <PortfolioTable showEditButton={true} showDeleteButton={true} limit={10} showPagination={true} />
      </div>
    </div>
  );
}

export default DashboardMain;

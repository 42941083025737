// Home.jsx
import React from "react";
import Header from "../Main-Header/HeaderComponent";
import PrimaryBtn from "../Primarybutton";
import Herosection from "../Herosection";
import Cta from "../Cta-components";
import { Helmet } from "react-helmet-async";
// import Whychooseus from "../Whychooseus";

import Servicescomponent from "../Servicescomponent";
import TestimonialSlider from "../Testimonial slider";
import LogoSlider from "../Logo-Slider";
import AuditProcess from "../Audit-Process";
import Whychooseusec from "../WhychooseusSec";
import CaseStudyCard from "../Case-study";
import Community from "../Joinourcommunity";
import Footer from "../Footer";

import PortfolioTable from "../PortfolioTable";
// import AuditProcess from "../Audit-Process";
// import AuditProcessSec from "../Audit-Process-sec";
import AuditProcessSec from "../test";

import HeroSection2 from "../Hero-section-2";
// Import images
import img10 from "../../assets/images/img11.svg";
import img14 from "../../assets/images/img14.svg";
// import serviceimg from "../../assets/images/services-img/service1.gif";
// import serviceimg2 from "../../assets/images/services-img/service2.gif";


// import serviceimg3 from "../../assets/images/services-img/service3.gif";
import gif1 from "../../assets/images/Hero-section/hero-gif.gif";
import "./style.css";
import WhyChooseUsSec from "../Why-choose-sec";

import csigma from '../../assets/images/cards/csigma.png'
import vanar from '../../assets/images/cards/vanar.png'
import lomad from '../../assets/images/cards/lomad.png'
import lomads from '../../assets/images/cards/lomad logo.png'
import ethernity from '../../assets/images/cards/ethernity.png'
// import bluecard from '../../assets/images/cards/bl'
import { useEffect } from "react";
import PageMeta from "../../PageMeta";

import { useState } from "react";
const Home = () => {



<PageMeta 
  title="Best Smart Contract Auditing Services Company | Expert Auditors - ImmuneBytes" 
  description="Get An Extensive Smart Contract Audit (Manual & Automated) Done By Our Team of Experienced Auditors and Safeguard Your Projects From Crypto Hacks, Exploits, & Vulnerabilities, While Optimizing Its Performance."
/>

  // useEffect(() => {
  //   document.title = "Immunebytes - Smart Contract Security";
  // }, []);

  const cardsData = [
    { text: "5+ ",content:"Experience in " ,line:"Web3 Security"},
    { text: "315+" ,content:"Happy Clients " ,line:"Globally"  },
    { text: "100+ ",content:"Critical Issues  " ,line:"Reported" }
    // { text: "5+ Years of Experience in Web3 Security" },
    // { text: "315+ Happy Clients Globally" },
    // { text: "100+ Critical Issues Reported" }
  ];
  const [portfolioLimit, setPortfolioLimit] = useState(5);

  useEffect(() => {
    const updateLimit = () => {
      const isMobile = window.innerWidth <= 768;
      setPortfolioLimit(isMobile ? 2 : 5);
    };

    updateLimit(); // Set initial limit
    window.addEventListener("resize", updateLimit);

    return () => window.removeEventListener("resize", updateLimit);
  }, []);


  const serviceimg2 = "https://res.cloudinary.com/dd81sfnzh/image/upload/v1741174263/Smart-Contract-Audit_epkpvi.gif";
  const serviceimg3 = "https://res.cloudinary.com/dd81sfnzh/image/upload/v1741174298/Blockchain-Audit_rljs0z.gif";
  const serviceimg = "https://res.cloudinary.com/dd81sfnzh/image/upload/v1741174305/Penetration-Testing-1_jfcq93.gif";
  
  return (
    <>

     <Helmet>
        <title>Best Smart Contract Auditing Services | Blockchain Security Auditors - ImmuneBytes</title>
        <meta name="description" content="Choose Immunebytes —One of the World's Leading Web3 / Blockchain Audit Firm—for Auditing Smart Contract Security for ✔Blockchains, ✔Defi, ✔Dapp, ✔Crypto, ✔NFTs and ✔Tokens" />
      </Helmet>
      <Header />
      <HeroSection2
        title="Threat Perspective,"
        highlight="Defense Expertise!"
        description="Securing the Web3 industry through rigorous code audits as we empower protocols with trust, transparency, and impenetrable defense."
        imageSrc={gif1}
        altText="Immunebytes Hero GIF"
      />
      {/* <Herosection /> */}

      <LogoSlider />
<div className="container">
<Cta title="Leading the Wave of Web3 Security" />
</div>
      <div className="container">
        <div className="services-wrapper">
          <h1 className="text-center  heading-h1 homepage-heading">
            Our Services
          </h1>
          <Servicescomponent
            imageSrc={serviceimg2}
            heading="Smart Contract Audit"
            paragraphtext="An extensive evaluation of your smart contract code for security, verification of business specifications, and adherence to industry standards for code reusability"
            link="/smart-contract-audit"
          />
          <div className="service-2">
            <Servicescomponent
              imageSrc={serviceimg3}
              heading="Blockchain Security Audit"
              paragraphtext="A thorough assessment of your blockchain’s security posture, encompassing architecture, network layer, transaction layer, ledger layer, and other frameworks."
              link="/blockchain-security-audit-services"
            />
          </div>
          <div className="service-3">
            <Servicescomponent
              imageSrc={serviceimg}
              heading="Penetration Testing"
              paragraphtext="Amid the rising incidence of traditional security breaches affecting Web3, ImmuneBytes provides penetration testing services explicitly tailored for Web3 applications."
              link="/penetration-testing-services"
            />
          </div>
        </div>
      </div>

      <div className="portfolio-container homapage-prtfolio">
        <PortfolioTable showEditButton={false} showDeleteButton={false} showPagination={false} limit={portfolioLimit} />
        <PrimaryBtn text="View More" onClick={() => window.location.href = "/portfolio"} />;

      </div>
      <h1 className="text-center  heading-h1 homepage-heading ">
        Audit Process
      </h1>
      <AuditProcessSec />

      {/* <AuditProcess /> */}
      <div className="container pb-4 why-sec-choose">
        <h1 className="text-center  heading-h1 homepage-heading">
          Why Choose Us?
        </h1>
        {/* <Whychooseus /> */}
        {/* <Whychooseusec /> */}

        <WhyChooseUsSec cards={cardsData} />
        {/* <img src={bluecard}></img> */}
      </div>
      <h1 className="text-center  heading-h1 testimonial-heading homepage-heading">
        Testimonials
      </h1>
      <div className="container testimonial-slider">
        <TestimonialSlider />
      </div>

      <div className="container">
        <div className=" case-study-wrapper">
          <h1 className="text-center  heading-h1 homepage-heading">
            Case Studies
          </h1>

          <div className="row">
            <div className="col-lg-6 md-6 col-sm-12">
              <div className="card-bg-1 pb-4 ">
                <CaseStudyCard
                  title="cSigma"
                  description="cSigma, a leading decentralized lending protocol, partnered with ImmuneBytes to secure their smart contracts. Through an in-depth audit, we identified critical vulnerabilities and implemented robust solutions, ...."
                  buttonText="Learn More"
                  imageSrc={csigma} // Replace with actual image URL
                />
              </div>
            </div>
            <div className="col-lg-6 md-6 col-sm-12">
              <div className="card-bg-2 pb-4 d-flex ">
                <CaseStudyCard
                  title="Vanar"
                  description="ImmuneBytes conducted a comprehensive blockchain audit for Vanar, a next-generation blockchain protocol focused on broadening the application of Web3 in gaming, VR/AR, AI,...."
                  buttonText="Learn More"
                  imageSrc={vanar} // Replace with actual image URL
                />
              </div>
            </div>
            <div className="col-lg-6 md-6 col-sm-12">
              <div className="card-bg-3 pt-4">
                <CaseStudyCard
                  title="Lomad"
                  description="ImmuneBytes collaborated with Lomad, decentralized platform facilitating the creation and management of DAOs, to conduct thorough penetration testing. By uncovering ...."
                  buttonText="Learn More"
                  imageSrc={lomads} // Replace with actual image URL
                />
              </div>
            </div>
            <div className="col-lg-6 md-6 d-flex  col-sm-12">
              <div className="card-bg-4 pt-4">
                <CaseStudyCard
                  title="Ethernity"
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore."
                  buttonText="Learn More"
                  imageSrc={ethernity} // Replace with actual image URL
                />
              </div>
            </div>
          </div>
          {/* <PrimaryBtn text="View More" /> */}
        </div>
      </div>

      <Community />
      <Footer />
    </>
  );
};

export default Home;

// App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useAuthContext } from "./Context/AuthContext.jsx";
import Home from "./components/Home";
import LandingPage from "./components/Landing-Page/index.jsx";
import Portfolio from "./components/Portfolio";
import Dashboard from "./Pages/user/Login/Dashboard";
import ForgotPassword from "./Pages/Forgot-password";
import ResetPassword from "./Pages/Reset-Password";
import OtpForm from "./Pages/user/Login/OtpForm";
import SeverityButtons from "./Pages/Savrity-Form";
import Layout from "./Pages/Layout/layout";
import DashboardMain from "./Pages/Dashboard-Main";
import AddPortfolio from "./Pages/Add-Portfolio";
import PlatformManagement from "./Pages/Add-Platform";
import UpdatePortfolio from "./Pages/Update-Portfolio";
import PrivateRoute from "./components/Private-Route/PrivateRoutes.jsx";
import Layout2 from "./components/Layout/";
import SmartContract from "./components/Services/SmartContractAudit/index.jsx";
import About from "./Pages/About/Index.jsx";
import Penetration from "./components/Services/penetrationTesting";
import BlockchainAudit from "./components/Services/BlockchainAudit/index.jsx";
import Defi from "./components/Services/Defi";
import ThankYouSection from "./components/ThankYou/index.jsx";
import Error from "./components/404Eror/";
import HeaderComponent from "./components/Main-Header/HeaderComponent.jsx";
import Blog from "./Pages/Blog/";
import Telegram from "./assets/images/telegram.svg";
import BlogPost from './components/BlogPost'
import "./App.css";
import AuditProcessSec from "./components/test/"
import ScrollToTop from "./components/Utils/scroolToTop.js";
// import ScrollToTop from "./Utils/scroolToTop.js";
// Style object for Telegram widget
const styles = {

  messageText: {
    margin: 0,
    fontSize: "14px",
    color: "#000",
  },
  closeButton: {
    position: "absolute",
    top: "-5px",
    right: "6px",
    background: "none",
    border: "none",
    fontSize: "18px",
    cursor: "pointer",
    color: "#555",
  },
  telegramButton: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  telegramIcon: {
    width: "50px",
    height: "50px",
  },
};

const checkLoginExpiration = () => {
  const loginTime = localStorage.getItem("loginTime");
  if (!loginTime) return;
  const loginDate = new Date(loginTime);
  const currentDate = new Date();
  if (currentDate.getTime() > loginDate.getTime() + 24 * 60 * 60 * 1000) {
    // console.log("Session expired. Logging out...");
    localStorage.clear();
    alert("Your session has expired. Please log in again.");
    window.location.href = "/dashboard";
  }
};

// Lazy Loding

const LazyLoadImages = () => {
  useEffect(() => {
    const images = document.querySelectorAll("img");
    images.forEach((img) => {
      img.setAttribute("loading", "lazy");
    });
  }, []);

  return null; // This component only modifies existing images
};

// Lazy Loding





function App() {
  const { authUser } = useAuthContext();
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => setIsVisible(false);

  useEffect(() => {
    // console.log("useEffect initialized");
    checkLoginExpiration();
    const interval = setInterval(checkLoginExpiration, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
        {/* <ScrollToTop/> */}
        <LazyLoadImages />
       <HelmetProvider>
       <Router future={{ v7_startTransition: true }}>
      <ScrollToTop />  {/* ✅ Place it inside the Router */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/landingpage" element={<LandingPage />} />
          {/* <Route path="/flipcard" element={<FlipCardContainer />} /> */}
          {/* <Route path="/flipcardanimation" element={<FlipCard />} /> */}



          
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="*" element={<Navigate to="/error" />} />
          <Route path="/otpform" element={<OtpForm />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword/:resetPasswordToken" element={<ResetPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/penetration-testing-services" element={<Layout2><Penetration /></Layout2>} />
          {/* <Route path="/blog" element={<Layout2><Blog /></Layout2>} /> */}
          <Route path="/blockchain-security-audit-services" element={<Layout2><BlockchainAudit /></Layout2>} />
          <Route path="/defi" element={<Layout2><Defi /></Layout2>} />
          <Route path="/thankyoupage" element={<Layout2><ThankYouSection /></Layout2>} />
          <Route path="/error" element={<Layout2><Error /></Layout2>} />
          <Route path="/about-us" element={<Layout2><About /></Layout2>} />
          <Route path="/mainheader" element={<HeaderComponent />} />
          <Route path="/dashboard-main" element={<PrivateRoute><Layout><DashboardMain /></Layout></PrivateRoute>} />
          <Route path="/severity" element={<PrivateRoute><Layout><SeverityButtons headname="Severity Dashboard" /></Layout></PrivateRoute>} />
          <Route path="/addportfolio" element={<PrivateRoute><Layout><AddPortfolio headname="Add Portfolio" /></Layout></PrivateRoute>} />
          <Route path="/updateportfolio/:selectedItemId" element={<PrivateRoute><Layout><UpdatePortfolio headname="Update Portfolio" /></Layout></PrivateRoute>} />
          <Route path="/addplatform" element={<PrivateRoute><Layout><PlatformManagement headname="Add Platform" /></Layout></PrivateRoute>} />
          {/* BlogPost */}
          <Route path="/BlogPost" element={<Layout2><BlogPost /></Layout2>} />

          <Route path="/AuditProcessSec" element={<Layout2><AuditProcessSec /></Layout2>} />

          
          {/* -------------------------------------------------------  */}


          {/* DUMMY -- FOR -- FUTURE --- REDIRECTION TO THE HOME PAGE  */}
          <Route path="/contact-us" element={<Home />} />
          <Route path="/audit-cost-calculator" element={<Home />} />
          <Route path="/client-testimonials" element={<Home />} />
          <Route path="/help" element={<Home />} />
          <Route path="/privacy-policy" element={<Home />} />
          <Route path="/disclaimer" element={<Home />} />
          {/* DUMMY -- FOR -- FUTURE --- REDIRECTION TO THE HOME PAGE  */}

          {/* -------------------------------------------------------  */}

          {/* DUMMY -- FOR -- FUTURE --- REDIRECTION TO THE SmartContract PAGE  */}
          <Route path="/defi-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/nft-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/token-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/dapp-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/solidity-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/rust-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/teal-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/haskell-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/ethereum-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/bsc-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/polygon-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/avalanche-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/near-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/solana-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/aurora-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/arbitrum-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/optimism-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/fantom-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/algorand-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/celo-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/zksync-era-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/cardano-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          <Route path="/polkadot-smart-contract-audit" element={<Layout2><SmartContract /></Layout2>} />
          {/* DUMMY -- FOR -- FUTURE --- REDIRECTION TO THE SmartContract PAGE  */}

          {/* -------------------------------------------------------  */}

          {/* DUMMY -- FOR -- FUTURE --- REDIRECTION TO THE BlockchainAudit PAGE  */}
          <Route path="/web3-security-consulting-services" element={<Layout2><BlockchainAudit /></Layout2>} />
          <Route path="/digital-assets-security-services" element={<Layout2><BlockchainAudit /></Layout2>} />
          <Route path="/blockchain-tools/" element={<Layout2><BlockchainAudit /></Layout2>} />
          {/* DUMMY -- FOR -- FUTURE --- REDIRECTION TO THE BlockchainAudit PAGE  */}


          {/* -------------------------------------------------------  */}

          {/* DUMMY -- FOR -- FUTURE --- REDIRECTION TO THE Penetration PAGE  */}
          <Route path="/mobile-app-penetration-testing-services" element={<Layout2><Penetration /></Layout2>} />
          <Route path="/web-app-penetration-testing-services" element={<Layout2><Penetration /></Layout2>} />
          {/* DUMMY -- FOR -- FUTURE --- REDIRECTION TO THE HOME PAGE  */}
x
        </Routes>
      </Router>
      </HelmetProvider>
      <div className="telegram-wrapper">
      {/* Welcome message and Telegram widget */}
      {isVisible && (
      
        <div className="telegram-message-box reveal" style={styles.messageBox}>
          <p style={styles.messageText}>
          Connect with our security experts!
          </p>
          <button style={styles.closeButton} onClick={handleClose}>&times;</button>
        </div>
      )}
      <a href="https://t.me/aabhas_sood" target="_blank" rel="noopener noreferrer">
        <button className="telegram-fixed" style={styles.telegramButton}>
          <img src={Telegram} alt="Telegram" style={styles.telegramIcon} />
        </button>
      </a>
    </div>
    </div>
  );
}

export default App;

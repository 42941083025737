import React from 'react';
import { Carousel, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ReactComponent as MapIcon } from '../../assets/images/WorldMap.svg';
import './style.css'

const MapSlider = () => {
  const cardData = [
    {
      title: 'Lomad',
      description: `ImmuneBytes collaborated with Lomad, decentralized platform facilitating 
      the creation and management of DAOs, to conduct thorough penetration testing. 
      By uncovering potential vulnerabilities and providing strategic mitigation measures, 
      ImmuneBytes ensured Lomad's evolving ecosystem was fortified against security threats, 
      enhancing its trustworthiness and user safety.`
    },
    {
      title: 'Vanar',
      description: `Robots can do audits, but the personal touch makes a difference. 
      That's why we love Immunebytes! Not only do they do top-class audits, 
      but they also take the time to understand our project and why certain things are done in specific ways. 
      They take the time to ensure we feel heard, which shows in their work.`
    },
    {
      title: 'cSigma',
      description: `cSigma, a leading decentralized lending protocol, partnered with ImmuneBytes to secure their smart contracts. 
      Through an in-depth audit, we identified critical vulnerabilities and implemented robust solutions, 
      ensuring the cSigma platform's reliability. The collaboration not only enhanced their security posture 
      but also enforced trust among its users. This case study showcases our expertise in delivering top-notch blockchain security solutions.`
    }
  ];

  return (
    <div className="container">
      <div className='about-map abt-map-slider text-center  p-4'>
        <h2 style={{ fontSize: '20px' }}>We’re here for you <br />
          <span>no matter where you are</span>
        </h2>
        <p className="sub-heading-section" style={{ fontSize: '16px' }}>
          Global Security Solutions for the Web3 Ecosystem. Providing expert auditing and support 
          to safeguard your blockchain projects, wherever you are in the world.
        </p>
        
        <div className='map position-relative'>
          <Carousel indicators={true} controls={true} interval={30000} className="w-100">
            {cardData.map((card, index) => (
              <Carousel.Item key={index} className="px-3">
                <Card className="mx-auto " style={{ maxWidth: '500px', background:'white'  }}>
                  <Card.Body>
                    <Card.Title style={{ fontSize: '24px' }}>{card.title}</Card.Title>
                    <Card.Text style={{ fontSize: '16px' }}>{card.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Carousel.Item>
            ))}
          </Carousel>
          {/* <MapIcon className='custom-svg position-absolute top-50 start-50 translate-middle' /> */}
        </div>
      </div>
    </div>
  );
};

export default MapSlider;

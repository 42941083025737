// import React from 'react';
// import './style.css';

// const OtherWeb3Services = ({ services }) => {
//   return (
//     <div className="container">
//       <div className="otherweb3services">
//         <h2>Other Web3 Services</h2>
//         <div className="otherweb3services-cards">
//           {services.map((service, index) => (
//             <a key={index} href={service.link} className="otherweb3services-card" target="_blank" rel="noopener noreferrer">
//               <div className="image">
//                 <img src={service.image} alt={service.title} />
//               </div>
//               <h3>{service.title}</h3>
//               <p>{service.description}</p>
//             </a>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default OtherWeb3Services;

// USING LINK HERE SO NEW TAB DO NOT OPEN
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import './style.css';

const OtherWeb3Services = ({ services }) => {
  return (
    <div className="container">
      <div className="otherweb3services">
        <h2>Other Web3 Services</h2>
        <div className="otherweb3services-cards">
          {services.map((service, index) => (
            <Link key={index} to={service.link} className="otherweb3services-card">
              <div className="image">
                <img src={service.image} alt={service.title} />
              </div>
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OtherWeb3Services;


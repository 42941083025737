import React from "react";
import "./style.css";

const WhyChooseUsSec = ({ cards }) => {
  const colorClasses = ["h2-orange", "h2-blue", "h2-green"]; // CSS classes

  return (
    <div className="why-choose-us">
      <div className="cards-container">
        {cards.map((card, index) => (
          <div key={index} className="card-new">
            {/* <h2>{card.text}</h2> */}
            <h2 className={colorClasses[index % colorClasses.length]}>{card.text}</h2> {/* Assign class */}
            <p>{card.content}</p>
            <h3>{card.line}</h3>

          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyChooseUsSec;

import React from "react";
import "./style.css";
import Aabhas from "../../assets/images/about-team/Aabhas-Sood-hvr.png";
import Naveen from "../../assets/images/about-team/Naveen-Rawat-hvr.png";
import Zaryab from "../../assets/images/about-team/Zaryab-Afser-hvr.png";
import Shubhi from "../../assets/images/about-team/Shubhi-Saran-hvr.png";
import Sheetal from "../../assets/images/about-team/Sheetal-Sinha-hvr.png";
import Jariruddin from "../../assets/images/about-team/Jariruddin.png";
import Kaif from "../../assets/images/about-team/Kaif.png";

import linkedin from "../../assets/images/about-team/Linkedin.svg"
import telegram from "../../assets/images/about-team/telegram.svg"


const Success = () => {
  const team = [
    {
      name: "Aabhas Sood",
      designation: "Founder & CEO",
      image: Aabhas,
      linkedin: "https://www.linkedin.com/in/aabhassood/",
      // https://www.linkedin.com/in/aabhassood/
      // telegram: "t.me/aabhas_sood",
      telegram: "https://t.me/aabhas_sood"

    },
    {
      name: "M. Jariruddin",
      designation: "Security Engineer",
      image: Jariruddin,
      linkedin: "https://www.linkedin.com/in/jarir-ju/",
      telegram: " https://t.me/jariruddin",
    },
    {
      name: "Kaif Ahmed",
      designation: "Security Engineer",
      image: Kaif,
      linkedin: " https://www.linkedin.com/in/0xkaifahmed/",
      telegram: " https://t.me/KaifAhmed1996",
    },
    {
      name: "Shubhi Saran",
      designation: "Tech & Growth",
      image: Shubhi,
      linkedin: "https://www.linkedin.com/in/shubhi-saran/",
      telegram: " https://t.me/shubhi_saran",
    },
    {
      name: "Sheetal Sinha",
      designation: "Business Development",
      image: Sheetal,
      linkedin: "https://www.linkedin.com/in/sheetal-sinha-109209163/",
      telegram: "https://t.me/Sheetal_ImmuneBytes",
    },
  ];

  return (
<div className="container">
<div className="about-success">
      <h2 className="section-heading">Our Success Team</h2>
      <p className="sub-heading-section">Driven by Expertise, United by Purpose. Our success is powered by a dedicated team who is committed to safeguarding the Web3 landscape. With a shared vision of driving innovation and security in the decentralized world, we work collaboratively to provide comprehensive, cutting-edge solutions that inspire trust and confidence in every project we support.</p>
      <div className="about-card">
        {team.map((member, index) => (
          <div className="card" key={index}>
            <img src={member.image} alt={member.name} />
            <div className="content">
              <h3>{member.name}</h3>
              <p>{member.designation}</p>
              <div className="social-links-team">
                <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                  <img src={linkedin} alt="" />
                </a>
                <a href={member.telegram} target="_blank" rel="noopener noreferrer">
                <img src={telegram} alt="" />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
</div>
  );
};

export default Success;

import React from 'react'
import Header from '../Main-Header/HeaderComponent'
import AuditSection from '../Audit-Section'
import PortfolioTable from '../PortfolioTable'
import Ctacomponents from '../Cta-components'
import './style.css'
import { Helmet } from 'react-helmet-async'
// import StatsSection from '../StatsComponent'
import Footer from '../Footer'
function Portfolio() {
  return (
    
<>
<Helmet>
        <title>Our List of Esteemed Clients for Web3 Blockchain Smart Contract Audit Services - ImmuneBytes</title>
        <meta name="description" content="Client Portfolio - ImmuneBytes" />
      </Helmet>
<Header/>
<AuditSection/>
<PortfolioTable showEditButton={false} showDeleteButton={false} showPagination={true} />
<div className="por-cta">
{/* <StatsSection/> */}
{/* <Ctacomponents title="Leading the Wave of Web3 Security"/> */}
<div className="secure-audit">
<h2>
Stay Ahead of the Security Curve.
</h2>
</div>
<Footer/>
</div>
</>
  )
}

export default Portfolio
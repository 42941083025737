import React from 'react';
import './style.css';
import PrimaryBtn from '../Primarybutton';
import Formpopup from '../Formpopup';
import iconarrow from '../../assets/images/iconarrow.svg'; // Correctly import the icon

const Cta = ({ title }) => {
  return (
  <>
      <div className="cta-wrapper-2">
        <h2>{title}</h2>
        <Formpopup
          auditName="Request Audit"
          buttonClassName="btn btn-primary open-popup-btn "
          arrowicon={iconarrow} // Pass the imported icon
        />
      </div>
      </>
  );
};

export default Cta;

import React , {useEffect, useState} from 'react';
import './style.css';
import github from "../../assets/images/github-white.svg"
import linkedin from "../../assets/images/linkedin-white.svg"
import axios from 'axios'
import  {useNavigate} from 'react-router-dom'
import { Link } from 'react-router-dom';
import telegram from "../../assets/images/twitter.svg";

const Footer = () => {

  const [message, setMessage] = useState("");
const navigate = useNavigate()

  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  // const appuri = process.env.REACT_APP_URL|| "http://localhost:3000"
  const appuri =` ${process.env.REACT_APP_URL}/api/v1/users/subscribe`|| "http://localhost:3000";


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(appuri, formData);
      const user = response.data.data;
  
      if (response.status === 200) {
        setMessage("Your Details have been submitted successfully!");
        setFormData({
          name: "",
          email: "",
        });
        navigate("/thankyoupage");
  
        // Reload the page after a slight delay to ensure navigation occurs
        setTimeout(() => {
          window.location.reload();
        }, 300); 
        
      } else {
        setMessage("Failed to submit. Please check the details!");
      }
    } catch (error) {
      setMessage("Failed to submit the form. Please try again later.");
    }
  };
  
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage("");
      }, 2000); // Message disappears after 3 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [message]);
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
        <div className="col-lg-3 col-md-6  footer-section  footer-section-4 footer-mobile">
            <h3>Subscribe</h3>
            <form>
              <label htmlFor="first&lastname">First & Last Name</label>
              <input type="text" placeholder="First & Last Name" id="name"
                    value={formData.name}
                    onChange={handleChange} />
              <label htmlFor="email">E-mail</label>
              <input type="email" placeholder="Email Address"  id="email"
                    value={formData.email}
                    onChange={handleChange} />
              <button type="submit">Submit</button>
            </form>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 footer-section footer-section-1">
            <h3>Home</h3>
            <ul>
              <li><a href="/portfolio">Portfolio</a></li>
              <li><a href="/about-us">About us</a></li>
              {/* <li><a href="#">Blog</a></li> */}
              {/* <a href="https://blog.immunebytes.com" target="_blank" rel="noopener noreferrer">Blog</a> */}
                  <Link
                              to="#"
                              className='blogfooter'
                              onClick={() => window.open("https://blog.immunebytes.com", "_blank", "noopener,noreferrer")}
                            >
                              Blog
                            </Link>
            </ul>
            <div className="social-icons">
              <a href="https://www.linkedin.com/company/immunebytes"><img src={linkedin} alt="" /></a>
              <a href="https://github.com/ImmuneBytes-Security-Audit"><img src={github} alt="" /></a>
               {/* <div className="x-btn"> */}
                            <a href="https://x.com/ImmuneBytes">
                              <button className="twit-x">
                                <img src={telegram} alt="" />
                              
                              </button>
                              </a>
                            {/* </div> */}
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6 footer-section footer-section-2">
            <h3>Services</h3>
            <ul>
              <li><a href="/smart-contract-audit">Smart Contract Audit</a></li>
              <li><a href="/blockchain-security-audit-services">Blockchain Security Audit</a></li>
              <li><a href="/penetration-testing-services">Penetration Testing</a></li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 footer-section footer-section-3">
            <h3>Contact us</h3>
            <p>
            Have questions or need a security audit for your next project? Reach out to our experts today and ensure your blockchain solutions are secure and reliable!



            </p>
          </div>

          <div className="col-lg-3 col-md-6  footer-section  footer-section-4 footer-tab">
          <h3>Subscribe</h3>
            <form onSubmit={handleSubmit}>
            {message && <p className="message text-[#F9116C] text-sm">{message}</p>}
              <label htmlFor="first&lastname">First & Last Name</label>
              <input type="text" placeholder="First & Last Name" id="name"
                    value={formData.name}
                    onChange={handleChange} />
              <label htmlFor="email">E-mail</label>
              <input type="email" placeholder="Email Address"  id="email"
                    value={formData.email}
                    onChange={handleChange} />
              <button type="submit">Submit</button>
            </form>
          </div>

          <div className="social-icons mobile-social">
          <a href="https://www.linkedin.com/company/immunebytes"><img src={linkedin} alt="" /></a>
          <a href="https://github.com/ImmuneBytes-Security-Audit"><img src={github} alt="" /></a>
          <a href="https://x.com/ImmuneBytes">
                              <button className="twit-x">
                                <img src={telegram} alt="" />
                              
                              </button>
                              </a>
            </div>

          <div className="col-12 footer-bottom  d-flex justify-content-between align-items-center">
            <p>©2025 ImmuneBytes. All Rights Reserved</p>
            <div className="footer-links">
              <a href="#">Terms of Service</a>
              <a href="#">Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react'
import './style.css'
// import main from '../../assets/images/About-main.png'
import WhyChooseUsSec from '../../components/Why-choose-sec'
import { Helmet } from 'react-helmet-async'
import LogoSlider from '../Logo-Slider'
import TrustedBy from '../../components/About-logo'
const Main = () => {

  const cardsData = [
    { text: "5+ ",content:"Experience in " ,line:"Web3 Security"},
    { text: "315+" ,content:"Happy Clients " ,line:"Globally"  },
    { text: "100+ ",content:"Critical Issues  " ,line:"Reported" }
  ];

  return (
    <>

<Helmet>
        <title>About Us - ImmuneBytes</title>
        <meta name="description" content="Secure Your Web3 Projects With ImmuneBytes—One of the Worlds Leading Smart Contract Audit Company, Sspecializing in ✔Blockchain Audit, ✔DeFi Audit, ✔Dapp Audit, ✔Token Audit, ✔NFT Audit & More." />
      </Helmet>


<div className="container">
<div className='About-main'>
  <div className="about-main-left-right">
    <div className="left">
    <div className='main'>
     <h2>We're Here To <span className='Red'>Guarantee Your Success</span></h2>
      <p>A blockchain security audit firm with the goal of making the Web3 space more secure to build through innovative and effective solutions.</p></div>
  
    </div>
    <div className="right">
      <div className="About-main-bg-img"></div>
      </div>
      </div>
      <div className="">

{/* <div className="Image">
  <img src={main} alt="" />
</div> */}

<TrustedBy/>


   {/* <LogoSlider /> */}
{/* <WhyChooseUsSec className=" pt-8 pb-4" cards={cardsData} /> */}


</div>
</div>
</div>
    </>
  )
}

export default Main

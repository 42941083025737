import React from "react";
import './style.css';

import strader from "../../assets/images/about-logo/strader.svg";
import group2 from "../../assets/images/about-logo/Group (2).svg";
import group3 from "../../assets/images/about-logo/Group (3).svg";
import group4 from "../../assets/images/about-logo/Group (4).svg";
import group5 from "../../assets/images/about-logo/Group (5).svg";
import group6 from "../../assets/images/about-logo/Group (6).svg";
import group7 from "../../assets/images/about-logo/Group (7).svg";
import group8 from "../../assets/images/about-logo/Group (8).svg";
import group9 from "../../assets/images/about-logo/Group (9).svg";
import group10 from "../../assets/images/about-logo/Group (10).svg";
import group11 from "../../assets/images/about-logo/Group (11).png";
import group12 from "../../assets/images/about-logo/Group (12).svg";
// import Formpopup from '../Formpopup';
// import iconarrow from '../../assets/images/iconarrow.svg'; // Correctly import the icon



const logos = [
  { src: strader },
  { src: group2 },
  { src: group3 },
  { src: group4 },
  { src: group5 },
  { src: group6 },
  { src: group7 },
  { src: group8 },
  { src: group9 },
  { src: group10 },
  { src: group11 },
  { src: group12 },


];

const TrustedBy = () => {
  return (
    <div className="text-center py-6 bg-black text-white trusted-div">
      <h3 className=" trust-heading ">Trusted By</h3>
      <div className="logos-about">
        {logos.map((logo, index) => (
          <div key={index} className="  ">
            <img src={logo.src} alt="logo" className="" />
          </div>
        ))}
      </div>
       {/* <Formpopup
                auditName="Request Audit"
                buttonClassName="btn btn-primary open-popup-btn "
                arrowicon={iconarrow} // Pass the imported icon
              /> */}
    </div>
  );
};

export default TrustedBy;

import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./style.css";

import onboarding from "../../assets/images/audit-process/onboarding.svg";
import coderefactoring from "../../assets/images/audit-process/code-refactoring.svg";
import auditinitition from "../../assets/images/audit-process/audit-initition.svg";
import finalauditreport from "../../assets/images/audit-process/final-audit-report.svg";
import initialauditreport from "../../assets/images/audit-process/initial-audit-report.svg";
import requirementgathering from "../../assets/images/audit-process/requirement-gathering.svg";
import onboardingpink from "../../assets/images/audit-process/onboarding-pink.svg";
import coderefactoringpink from "../../assets/images/audit-process/code-refactoring-pink.svg";
import auditinititionpink from "../../assets/images/audit-process/audit-initition-pink.svg";
import finalauditreportpink from "../../assets/images/audit-process/final-audit-report-pink.svg";
import initialauditreportpink from "../../assets/images/audit-process/initial-audit-report-pink.svg";
import requirementgatheringpink from "../../assets/images/audit-process/requirement-gathering-pink.svg";
import rightarrow from "../../assets/images/audit-process/right.svg";
import leftarrow from "../../assets/images/audit-process/left.svg";





const AuditProcessSec = () => {
  return (
<div className="container">
      <div className=" pb-5">
      <SlideTabs />
    </div>
</div>
  );
};

const SlideTabs = () => {
  const [position, setPosition] = useState({ left: 0, width: 0, opacity: 0 });
  const tabContainerRef = useRef(null);


  // Tabs Data
  const tabs = [
    { step: "01", title: "Onboarding", description: "Kick off the process with a seamless onboarding experience tailored to your project's needs.", icon: onboarding ,icon1 :onboardingpink},
    { step: "02", title: "Requirement Gathering", description: "Gather all the necessary requirements to ensure a smooth audit process.", icon: requirementgathering ,icon1 :requirementgatheringpink },
    { step: "03", title: "Audit Initiation", description: "Initiate the audit process to identify areas of improvement.", icon: auditinitition ,icon1 :auditinititionpink},
    { step: "04", title: "Initial Audit Report", description: "Receive a detailed initial audit report for your review.", icon: initialauditreport ,icon1 :initialauditreportpink },
    { step: "05", title: "Code Refactoring", description: "Refactor your codebase to meet the audit recommendations.", icon: coderefactoring  ,icon1 :coderefactoringpink},
    { step: "06", title: "Final Audit Report", description: "Get the final audit report with a summary of improvements made.", icon: finalauditreport ,icon1 :finalauditreportpink },
  ];

  // Auto-scroll effect every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    if (tabContainerRef.current) {
      tabContainerRef.current.scrollLeft += tabContainerRef.current.clientWidth;
      if (
        tabContainerRef.current.scrollLeft + tabContainerRef.current.clientWidth >=
        tabContainerRef.current.scrollWidth
      ) {
        tabContainerRef.current.scrollLeft = 0;
      }
    }
  };

  const prevSlide = () => {
    if (tabContainerRef.current) {
      tabContainerRef.current.scrollLeft -= tabContainerRef.current.clientWidth;
      if (tabContainerRef.current.scrollLeft <= 0) {
        tabContainerRef.current.scrollLeft = tabContainerRef.current.scrollWidth;
      }
    }
  };

  

  return (
    <div className="relative flex flex-col items-center mx-auto w-fit main-wrapper">
      <ul
        ref={tabContainerRef}
        onMouseLeave={() => setPosition((pv) => ({ ...pv, opacity: 0 }))}
        className="relative flex ul-wrapper overflow-hidden whitespace-nowrap border-2 border-black bg-black p-1 h-5 scroll-smooth "
        style={{ scrollbarWidth: "none", overflowX: "auto" }}
      >
        {tabs.map((tab, index) => (
          <Tab key={index} step={tab.step} title={tab.title} description={tab.description} icon={tab.icon} setPosition={setPosition} icon1={tab.icon1} />
        ))}
        <Cursor position={position} />
      </ul>
      <div className="flex space-x-4 mt-4 absolute bottom-[-60px] right-4 left-right-arrow-div">
        <button className=" left-right-arrow custom-rounded-btn" onClick={prevSlide}><img src={leftarrow} alt="" /></button>
        <button className=" left-right-arrow custom-rounded-btn" onClick={nextSlide}><img src={rightarrow} alt="" /></button>
      </div>
    </div>
  );
};

const Tab = ({ step, title, description, icon,icon1, setPosition }) => {
  const ref = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


// useEffect(() => {
//   const handleResize = () => setIsMobile(window.innerWidth < 768);
//   window.addEventListener("resize", handleResize);
//   return () => window.removeEventListener("resize", handleResize);
// }, []);

  return (
    <li
      ref={ref}
      onMouseEnter={() => {
        if (!ref?.current) return;
        const { width } = ref.current.getBoundingClientRect();
        setPosition({ left: ref.current.offsetLeft, width, opacity: 1 });
        setIsHovered(true);

      }}
      onMouseLeave={() => setIsHovered(false)}
      className="relative z-10 block cursor-pointer  px-3 py-1.5 text-xs uppercase md:px-5 md:py-3 md:text-base li-width border overflow-visible whitespace-normal hover:text-white text-[#797979] auditprocess-sec-li-text-white-mob "
    >
      <div className="flex flex-col space-y-1">
        <div className="flex items-center space-x-3 audit-process-slider-card">
          <span className=" font-bold audit-process-slider-step">{step}</span>
          {/* <span className="text-2xl">{icon}</span> */}
          <img  className="text-2xl" src={isMobile ? icon1 : isHovered ? icon1 : icon}  alt="icon-testing" />
        </div>
        <h3 className="text-xl font-bold pb-6">{title}</h3>
        <h5 className="text-sm ">{description}</h5>
      </div>
    </li>
  );
};

const Cursor = ({ position }) => {
  return <motion.li animate={{ ...position }} className="absolute z-0 h-100 bg-[#191919] md:h-12 " />;
};

export default AuditProcessSec;
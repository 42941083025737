import { useState, useRef } from "react";
import { ChevronDown } from "lucide-react";
import { motion } from "framer-motion";
import "./DesktopMenu.css";

export default function DesktopMenu({ menu }) {
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredSubMenuIndex, setHoveredSubMenuIndex] = useState(null);
  const [activeSubSubMenu, setActiveSubSubMenu] = useState(null);
  const timeoutRef = useRef(null);

  const subMenuAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      display: "block",
      transition: {
        
        duration: 1,
      },
    },
    exit: {
      opacity: 0,
      rotateX: -15,
      transition: {
        duration: 3,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };

  const hasSubMenu = menu?.subMenu?.length;

  const handleSubMenuEnter = (i) => {
    clearTimeout(timeoutRef.current);
    setHoveredSubMenuIndex(i);
    setActiveSubSubMenu(menu.subMenu[i].name);
  };

  const handleSubMenuLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setHoveredSubMenuIndex(null);
      setActiveSubSubMenu(null);
    }, 2000); // 5 seconds delay
  };

  return (
    <motion.li
      className="group/link"
      key={menu.name}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
        setHoveredSubMenuIndex(null);
        setActiveSubSubMenu(null);
        clearTimeout(timeoutRef.current);
      }}
    >
      <a
        href={menu.link || "#"}
        className="flex-center gap-1 hover:bg-white/5 cursor-pointer px-3 py-1 rounded-xl"
      >
        {menu.name}
        {hasSubMenu && (
          <motion.div animate={{ rotate: isHovered ? 180 : 0 }}>
            <ChevronDown className="mt-[0.6px]" />
          </motion.div>
        )}
      </a>

      {hasSubMenu && (
        <motion.div
          className="sub-menu menu-container"
          initial="exit"
          animate={isHovered ? "enter" : "exit"}
          variants={subMenuAnimate}
        >
          <div
            className={`grid ${
              menu.gridCols === 3
                ? "grid-cols-3"
                : menu.gridCols === 2
                ? "grid-cols-2"
                : "grid-cols-1"
            }`}
          >
            {menu.subMenu.map((submenu, i) => (
              <div
                className="relative cursor-pointer group/submenu"
                key={submenu.name || i}
                onMouseEnter={() => handleSubMenuEnter(i)}
                onMouseLeave={handleSubMenuLeave}
              >
                {menu.gridCols > 1 && menu?.subMenuHeading?.[i] && (
                  <p className="text-sm mb-4 text-gray-500">
                    {menu?.subMenuHeading?.[i]}
                  </p>
                )}

                <motion.a
                  href={submenu.link || "#"}
                  className="flex-center gap-x-4 group/menubox"
                  onClick={() => setActiveSubSubMenu(submenu.name)}
                  whileHover={{ scale: 1.02 }}
                 
                >
                  <div>{submenu.icon && <submenu.icon />}</div>
                  <div>
                    <h6 className="font-semibold text-white">
                      {submenu.name}
                    </h6>
                    <p className="text-sm text-gray-400">{submenu.desc}</p>
                  </div>
                </motion.a>

                {(submenu.subSubMenu1 || submenu.subSubMenu2) &&
                  (hoveredSubMenuIndex === i ||
                    activeSubSubMenu === submenu.name) && (
                    <motion.div
                      className={`subsubmenu-wrapper absolute left-full sub-submenu subsubmenu sub-menu ${
                        hoveredSubMenuIndex === i ||
                        activeSubSubMenu === submenu.name
                          ? "activehover"
                          : ""
                      }`}
                      initial="exit"
                      animate="enter"
                      variants={subMenuAnimate}
                    >
                      <motion.ul
                        initial="exit"
                        animate="enter"
                        variants={subMenuAnimate}
                      >
                        {submenu.subSubHeading1 && (
                          <p className="py-2 text-sm font-semibold text-white">
                            {submenu.subSubHeading1}
                          </p>
                        )}
                        <div className="li-wrapper">
                          {submenu.subSubMenu1?.map((subSubItem, j) => (
                            <motion.a
                              href={subSubItem.link || "#"}
                              key={subSubItem.name || j}
                              className="block px-4 py-2 hover:bg-gray-700 text-white cursor-pointer submenuitem flex items-center gap-2"
                              onClick={() =>
                                setActiveSubSubMenu(subSubItem.name)
                              }
                              whileHover={{ scale: 1 }}
                            
                            >
                              {subSubItem.icon && (
                                <img
                                  src={subSubItem.icon}
                                  alt={subSubItem.name}
                                />
                              )}
                              <p className="sub-item-name">
                                {subSubItem.name}
                              </p>
                            </motion.a>
                          ))}
                        </div>

                        {submenu.subSubHeading2 && (
                          <p className="py-2 text-sm font-semibold text-white">
                            {submenu.subSubHeading2}
                          </p>
                        )}
                        <div className="li-wrapper">
                          {submenu.subSubMenu2?.map((subSubItem, j) => (
                            <motion.a
                              href={subSubItem.link || "#"}
                              key={subSubItem.name || j}
                              className="block px-4 py-2 hover:bg-gray-700 text-white cursor-pointer submenuitem flex items-center gap-2"
                              onClick={() =>
                                setActiveSubSubMenu(subSubItem.name)
                              }
                              whileHover={{ scale: 1 }}
                            >
                              {subSubItem.icon && (
                                <img
                                  src={subSubItem.icon}
                                  alt={subSubItem.name}
                                />
                              )}
                              <p className="sub-item-name">
                                {subSubItem.name}
                              </p>
                            </motion.a>
                          ))}
                        </div>
                      </motion.ul>
                    </motion.div>
                  )}
              </div>
            ))}
          </div>
        </motion.div>
      )}
    </motion.li>
  );
}

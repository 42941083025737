import React from 'react'
import LoginForm from '../'

function Dashboard() {
  return (
   <LoginForm/>
  )
}

export default Dashboard



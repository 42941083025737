import React, { useState } from "react";
import "./style.css"; // Create this CSS file
import close from "../../assets/images/portfolio/close-btn.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const FormpopupLandingPage = ({ auditName = "", buttonClassName = "", arrowicon = "" }) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    email: "",
    projectwebsite: "",
    githublink: "",
    services: "",
    auditdeadline:new Date().toISOString().split("T")[0],
  });
  const [message, setMessage] = useState("");

  const togglePopup = () => setShowPopup(!showPopup);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  // const appuri = process.env.REACT_APP_URL|| "http://localhost:3000"
  const appuri =` ${process.env.REACT_APP_URL}/api/v1/users/requestquotelanding`|| "http://localhost:3000";

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(appuri, formData);
      if (response.status === 200) {
        setMessage("Your request has been submitted successfully!");
        // setMessage("Your request has been submitted successfully!");
        setFormData({
          name: "",
          username: "",
          email: "",
          projectwebsite: "",
          githublink: "",
          services: "",
          auditdeadline: new Date().toISOString().split("T")[0],
        });
        setShowPopup(false); // Close the popup
        navigate("/thankyoupage")
      } else {
        setMessage("Failed to submit. Please check the details!");
        navigate("/error")

      }
    } catch (error) {
      setMessage("Failed to submit the form. Please try again later.");
    }
  };
  const handleChangedate = (e) => {
    const selectedDate = e.target.value;
    const today = new Date().toISOString().split("T")[0];
    if (selectedDate < today) {
      alert("You cannot select a past date.");
      return;
    }
    setFormData({
      ...formData,
      auditdeadline: selectedDate,
    });
  };
  return (
    <div className="popup-container">
      {/* <button
        className={`open-popup-btn btn text-white ${buttonClassName}`}
        onClick={togglePopup}
      >
        {auditName}
        {arrowicon && <img src={arrowicon} alt="Button Icon" className="btn-icon" />}
      </button> */}

      {/* {showPopup && ( */}
        <div className="popup-overlay-Landing-Page">
          <div className="popup-form-Landing-Page">
            <h3>Request Audit</h3>
            {message && <p className="message">{message}</p>}
            <form onSubmit={handleSubmit} className="form-landing-page">
              <div className="form-group-landing-page">
                <div className="input-flex-landing-page">
                  <label htmlFor="name"></label>
                  <input
                    type="text"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter your name"
                    required
                  />
                </div>     </div>
                <div className="form-group-landing-page">
                <div className="input-flex-landing-page">
                  <label htmlFor="email"></label>
                  <input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                    required
                  />
                </div>
              </div>

              <div className="form-group-landing-page">
                <div className="input-flex-landing-page">
                  <label htmlFor="projectwebsite"></label>
                  <input
                    type="text"
                    id="projectwebsite"
                    value={formData.projectwebsite}
                    onChange={handleChange}
                    placeholder="Enter your project website"
                    required
                  />
                </div>  
                </div>
              {/* <div className="form-group-landing-page">
                <div className="input-flex-landing-page">
                  <label htmlFor="githublink"></label>
                  <input
                    type="text"
                    id="githublink"
                    value={formData.githublink}
                    onChange={handleChange}
                    placeholder="Enter GitHub/Smart Contract link"
                    required
                  />
                </div>
              </div> */}

              <div className="form-group-landing-page">
                <div className="input-flex-landing-page">
                  <label htmlFor="username"></label>
                  <input
                    type="text"
                    id="username"
                    value={formData.username}
                    onChange={handleChange}
                    placeholder="Enter your username-Telegram/Skype/X"
                    required
                  />
                </div>        
                </div>
              {/* <div className="form-group-landing-page"> 
                <div className="input-flex-landing-page">
                  <label htmlFor="services"></label>
                  <select
                    id="services"
                    value={formData.services}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      Select a service
                    </option>
                    <option value="Service 1">Smart Contract Audit</option>
                    <option value="Service 2">Blockchain Security Audit</option>
                    <option value="Service 3">Penetration Testing</option>
                  </select>
                </div>
              </div>
              <div className="form-group-landing-page">
                <div className="input-flex-landing-page">
                  <label htmlFor="auditdeadline"></label>
                  <input
                    type="date"
                    id="auditdeadline"
                    value={formData.auditdeadline || new Date().toISOString().split("T")[0]}
                    onChange={handleChangedate}
                    required
                    min={new Date().toISOString().split("T")[0]} // Prevent past dates
                    // style={{ width: '100%' }}
                  />
                </div>
              </div> */}

              <button type="submit" className="submit-btn">
                Submit
              </button>
            </form>

            {/* <button className="close-popup-btn" onClick={togglePopup}>
              <img src={close} alt="Close" />
            </button> */}
          </div>
        </div>
      {/* // )} */}
    </div>
  );
};

export default FormpopupLandingPage;
